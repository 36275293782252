import { useUserStore } from '@/stores/user';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  await userStore.fetchUser();

  if (userStore.user.type != 'admin') {
    return navigateTo('/free');
  }
});
